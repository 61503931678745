<template>
  <div class="container-page-download">
    <div class="conatiner-download">
      <div class="container-info-download">
        <h1>Desarga Quac</h1>
        <p>Descarga nuestra aplicación de crédito personal y consigue el dinero que necesitas de manera segura, sencilla y rápida.</p>
        <div class="d-flex flex-wrap justify-content-center">
          <a href="#" class="pe-2">
            <img src="@/assets/download_on_the_app_store.svg" alt="descarga Quac AppStore">
          </a>
          <a href="#" class="ps-2">
            <img src="@/assets/disponible-en-google-play.svg" alt="descarga Quac playstore">
          </a>
        </div>
      </div>
      <img class="img-float-1" src="@/assets/float-1.png">
      <img class="img-float-2" src="@/assets/float-2.png">
      <img class="img-float-3" src="@/assets/float-3.png">
      <img class="img-float-4" src="@/assets/float-4.png">
      <img class="img-float-5" src="@/assets/float-5.png">
      <img class="img-float-6" src="@/assets/float-6.png">
    </div>
    <div class="container-imgs-app">
      <img class="img-app" src="@/assets/img-descarga-1.png">
      <img class="img-app" src="@/assets/img-descarga-2.png">
      <img class="img-app" src="@/assets/img-descarga-3.png">
      <img class="img-app" src="@/assets/img-descarga-4.png">
    </div>
    <carousel
      class="d-flex d-md-none w-100 position-relative owl-carousel-container carousel-tp-1 my-5"
      :dots="false" 
      :lazyLoad="true" 
      :nav="false" 
      :stagePaddin="0"
      :key="'carousel-1'"
      :responsive="{ 0:{items:1, center:true, nav:false, stagePadding: 30}, 530:{items:2, center:true, nav:false, stagePadding:10}}">
      <img class="px-2 px-xs-4" style="max-width: 360px;" src="@/assets/img-descarga-1.png">
      <img class="px-2 px-xs-4" style="max-width: 360px;" src="@/assets/img-descarga-2.png">
      <img class="px-2 px-xs-4" style="max-width: 360px;" src="@/assets/img-descarga-3.png">
      <img class="px-2 px-xs-4" style="max-width: 360px;" src="@/assets/img-descarga-4.png">
    </carousel>
    <div class="container-box-download">
      <h3>Descarga la App en tu dispositivo</h3>
      <div class="box-links">
        <p>¡No esperes más y descarga nuestra aplicación ahora mismo para disfrutar de todos los beneficios!</p>
        <div class="d-flex flex-wrap justify-content-center w-100">
          <a href="#" class="pe-2">
            <img src="@/assets/download_on_the_app_store.svg" alt="descarga Quac AppStore">
          </a>
          <a href="#" class="ps-2">
            <img src="@/assets/disponible-en-google-play.svg" alt="descarga Quac AppStore">
          </a>
        </div>
      </div>
    </div>
    <div class="container-imgs-app">
      <img class="img-app" src="@/assets/img-descarga-5.png">
      <img class="img-app" src="@/assets/img-descarga-6.png">
      <img class="img-app" src="@/assets/img-descarga-7.png">
      <img class="img-app" src="@/assets/img-descarga-8.png">
    </div>
    <carousel
      class="d-flex d-md-none w-100 position-relative owl-carousel-container carousel-tp-1 my-5"
      :dots="false" 
      :lazyLoad="true" 
      :nav="false" 
      :stagePaddin="0"
      :key="'carousel-1'"
      :responsive="{ 0:{items:1.2, center:true, nav:false, stagePadding:30}}">
      <img class="px-4" style="max-width: 360px;" src="@/assets/img-descarga-5.png">
      <img class="px-4" style="max-width: 360px;" src="@/assets/img-descarga-6.png">
      <img class="px-4" style="max-width: 360px;" src="@/assets/img-descarga-7.png">
      <img class="px-4" style="max-width: 360px;" src="@/assets/img-descarga-8.png">
    </carousel>
  </div>
</template>
<script>
import carousel from 'v-owl-carousel'
export default {
  components: { carousel },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.container-page-download {
  .conatiner-download {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    padding: 2rem;
    background-image: url('../assets/bg-descarga.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    .container-info-download {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      max-width: 604px;
      position: relative;
      z-index: 2;
      overflow: hidden;
      h1 {
        width: 100%;
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 72px;
        margin-bottom: 2rem;
      }
      p {
        color: white;
        margin-bottom: 2rem;
        text-align: center;
      }
    }
    .img-float- {
      &1 {
        position: absolute;
        bottom: -1rem;
        left: 20%;
        animation: animationUpDown 3.9s ease-in-out infinite alternate;
      }
      &2 {
        position: absolute;
        bottom: 0px;
        right: 30%;
        animation: animationUpDown 3.5s ease-in-out infinite alternate;
      }
      &3 {
        position: absolute;
        bottom: 20%;
        left: 10%;
        animation: animationUpDown 3.3s ease-in-out infinite alternate;
      }
      &4 {
        position: absolute;
        bottom: 40%;
        right: 10%;
        animation: animationUpDown 2.8s ease-in-out infinite alternate;
      }
      &5 {
        position: absolute;
        top: 20%;
        left: 15%;
        animation: animationUpDown 3s ease-in-out infinite alternate;
      }
      &6 {
        position: absolute;
        bottom: 20%;
        right: 18%;
        animation: animationUpDown 3.8s ease-in-out infinite alternate;
      }
    }
    @media (max-width: 768px) {
      .img-float- {
        &1 {
          left: -1.5rem;
          width: 72px;
        }
        &2 {
          position: absolute;
          right: -1.3rem;
          width: 58px;
        }
        &3 {
          position: absolute;
          bottom: 20%;
          left: -1.5rem;
          width: 62px;
        }
        &4 {
          position: absolute;
          bottom: 40%;
          right: -1rem;
          width: 67px;
        }
        &5 {
          position: absolute;
          top: 20%;
          left: -1rem;
          width: 64px;
        }
        &6 {
          position: absolute;
          bottom: 20%;
          right: 5%;
          width: 50px;
        }
      }
    }
  }
  .container-box-download {
    background-image: url('../assets/bg-carousel-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 1rem;
    padding-bottom: 0;
    h3 {
      width: 100%;
      text-align: center;
      margin-bottom: 1.5rem;
      font-weight: bold;
    }
    .box-links {
      background-color: #DBE1E8;
      padding: 2rem;
      max-width: 720px;
      width: 100%;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      p {
        width: 352px;
        margin-bottom: 1rem;
      }
    }
    @media (max-width: 991px) {
      background-position: right;
      background-size: auto;
    }
  }
  .container-imgs-app {
    margin-top: 4rem;
    max-width: 1088px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    padding: 0 2rem;
    .img-app {
      max-width: 24%;
      width: 260px;
      margin-right: auto;
      margin-left: auto;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>